import { usePathname, useSearchParams, useRouter } from 'next/navigation';

import { queryParamRedirectedFromDashboard } from 'common/constants/common';

const useRouteWithLanguage = () => {
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();

  const hasQueryParamMyNewPath = (path: string) => {
    const queryParams = new URLSearchParams(path.split('?')[1] || '');

    return queryParams.toString() !== '';
  };

  const addProjectIdOrNot = (pathName: string) => {
    let newPath = pathName;
    const projectId = searchParams.get(queryParamRedirectedFromDashboard);
    const hasQueryParamsNewRoute = hasQueryParamMyNewPath(newPath);

    if (projectId) {
      newPath += hasQueryParamsNewRoute
        ? `&${queryParamRedirectedFromDashboard}=${projectId}`
        : `?${queryParamRedirectedFromDashboard}=${projectId}`;
    }

    return newPath;
  };

  const push = (pathName: string) => {
    const urlPath = addProjectIdOrNot(pathName);
    router.push(urlPath);
  };

  const replace = (pathName: string) => {
    const urlPath = addProjectIdOrNot(pathName);
    router.replace(urlPath);
  };

  return {
    ...router,
    pathname,
    replace,
    push
  };
};

export default useRouteWithLanguage;
