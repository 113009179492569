export enum DomainsEnum {
  accountsBackend = 'accountsBackend',
  appStoreBackend = 'appStoreBackend',
  accountsClient = 'accountsClient',
  appStoreClient = 'appStoreClient',
  dashboardClient = 'dashboardClient',
  myBrandBooks = 'myBrandBooks',
  canonicalURL = 'canonicalURL',
  storageUrl = 'storageUrl'
}

export enum RequestEnum {
  accountsFetcher = 'accountsFetcher',
  applicationsFetcher = 'applicationsFetcher'
}

export enum HttpMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export enum ApiVersionsEnum {
  V1 = 'v1',
  V2 = 'v2',
  V3 = 'v3'
}

export enum HeadersEnum {
  contentType = 'Content-Type',
  timeZone = 'TimeZone',
  authorization = 'Authorization',
  xCurrentLocale = 'x-current-locale',
  xProjectId = 'x-project-id',
  accept = 'accept'
}

export enum ContentTypesEnum {
  formData = '',
  applicationJson = 'application/json'
}

export enum BackendResponseModelTypes {
  WithData = 'WithData',
  WithNoDataProperty = 'WithNoDataProperty'
}

export enum FilterAndSortOrientations {
  desc = 'desc',
  asc = 'asc'
}

export enum FilterAndSortColumns {
  createdAt = 'createdAt',
  helpfulCount = 'helpfulCount'
}
