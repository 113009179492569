import { ITypographyTypes } from 'common/globalTypes/interfaces/typography';
import { KeyValuePair } from 'common/globalTypes/utilityTypes/common';
import { IStyleTypes } from 'common/globalTypes/interfaces/buttons';

const responsiveSizes = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px'
};

export const media = {
  sm: (styles: string) => `
    @media only screen and (max-width: ${responsiveSizes.sm}) {
      ${styles}
    }
  `,
  md: (styles: string) => `
    @media only screen and (max-width: ${responsiveSizes.md}) {
      ${styles}
    }
  `,
  lg: (styles: string) => `
     @media only screen and (max-width: ${responsiveSizes.lg}) {
        ${styles}
      }
  `,
  xl: (styles: string) => `
    @media only screen and (max-width: ${responsiveSizes.xl}) {
      ${styles}
    }
  `,
  xxl: (styles: string) => `
    @media only screen and (max-width: ${responsiveSizes.xxl}) {
      ${styles}
    }
  `
};

export const colors = {
  red_6: '#f5222d',
  primary_6: '#235DF4',
  primary_5: '#40a9ff',
  primary_4: '#75A5FF',
  primary_2: '#C7DEFF',
  primary_1: '#F0F7FF',
  gray_10: '#D6D6D6',
  gray_8: '#595959',
  gray_7: '#8C8C8C',
  gray_9: '#262626',
  gray_6: '#bfbfbf',
  gray_5: '#D9D9D9',
  gray_4: '#F0F0F0',
  gray_3: '#f5f5f5',
  gray_2: '#fafafa',
  gray_1: '#FFFFFF',
  blue_4: '#69b1ff',
  blue_6: '#1677ff',
  blue_1: '#e6f4ff',
  blue_2: '#e9f2ff',
  yellow_1: '#fadB14',
  green_2: '#D9F7BE',
  green_6: '#52C41A'
};

export const sizes = {
  sp3xs: '2px',
  sp2xs: '4px',
  spXs: '6px',
  sps: '8px',
  sp3xm: '10px',
  sp2xm: '12px',
  spXm: '14px',
  spm: '16px',
  spl: '20px',
  spXl: '24px',
  sp2xl: '32px',
  sp3xl: '40px',
  sp4xl: '48px',
  sp5xl: '56px',
  sp6xl: '64px',
  sp7xl: '72px',
  sp8xl: '80px',
  sp9xl: '88px',
  sp10xl: '96px',
  sp11xl: '104px',
  sp12xl: '112px',
  sp13xl: '120px',
  sp14xl: '128px',
  sp15xl: '136px',
  sp16xl: '144px',
  sp17xl: '180px'
};

export const templateWidth = {
  web: {
    maxWidth: '1280px'
  },
  mobile: {}
};

export const HEIGHT = {
  WEB: {
    HEADER: '64px'
  },
  MOBILE: {
    HEADER: '56px'
  }
};

export const zIndex = {
  mainHeader: 990,
  templatePopup: 991,
  checkoutModal: 992,
  appDetailsListGradient: 10
};

export const letterSpacingBase = '2%';
export const borderRadiusBase = '12px';
export const borderRadiusMedium = '16px';

export const buttonSizes: KeyValuePair<IStyleTypes> = {
  large: {
    height: sizes.sp3xl,
    padding: `${sizes.spl} ${sizes.spl}`,
    fontSize: sizes.spXm
  },
  middle: {
    height: sizes.sp3xl,
    padding: `${sizes.spl} ${sizes.spl}`,
    fontSize: sizes.spXm
  },
  small: {
    height: sizes.sp3xl,
    padding: `${sizes.spl} ${sizes.spl}`,
    fontSize: sizes.spXm
  },
  extraSmall: {
    height: sizes.sp2xl,
    padding: `${sizes.spl} ${sizes.spl}`,
    fontSize: sizes.spXm
  }
};

export const typography: ITypographyTypes = {
  bodyText: {
    size: {
      base: '16px',
      small: '14px',
      extraSmall: '12px',
      large: '20px',
      extraLarge: '24px'
    },
    lineHeight: {
      extraSmall: '20px',
      small: '22px',
      base: '24px',
      large: '28px',
      extraLarge: '32px'
    },
    letterSpacing: {
      base: letterSpacingBase,
      extraSmall: letterSpacingBase,
      small: letterSpacingBase,
      large: letterSpacingBase,
      extraLarge: letterSpacingBase
    }
  },
  link: {
    size: {
      small: '14px',
      base: '16px'
    },
    lineHeight: {
      small: '22px',
      base: '24px'
    },
    letterSpacing: {
      base: letterSpacingBase,
      small: letterSpacingBase
    }
  },
  heading: {
    size: {
      h1: '32px',
      h2: '28px',
      h3: '24px',
      h4: '20px'
    },
    lineHeight: {
      h1: '44px',
      h2: '40px',
      h3: '36px',
      h4: '28px'
    },
    letterSpacing: {
      h1: '1.2%',
      h2: '1.2%',
      h3: '1.6%',
      h4: '2%'
    }
  },
  displayHeading: {
    size: {
      h1: '64px',
      h2: '56px',
      h3: '48px',
      h4: '40px'
    },
    lineHeight: {
      h1: '76px',
      h2: '68px',
      h3: '60px',
      h4: '52px'
    },
    letterSpacing: {
      h1: '0',
      h2: '0',
      h3: '1%',
      h4: '1%'
    }
  },
  fontWeight: {
    regular: 'normal',
    medium: '500',
    bold: 'bold'
  }
};
