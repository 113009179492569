export enum RoutesEnum {
  HOME = '/',
  SEARCH = '/search',
  CATEGORY = '/category',
  CHECKOUT = '/checkout'
}

export enum LangMappingEnum {
  en = 'en'
}

export enum GalleryTypeEnum {
  image = 'image',
  video = 'video'
}

export enum SinglePageAppSectionIds {
  about = 'about',
  benefits = 'benefits',
  details = 'details',
  ratings = 'ratings'
}
